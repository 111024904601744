
.right-container{
    bottom: 0;
    box-shadow: 5px 5px 20px #0000000F;
    display: inline-block;
    height: 100%;
    // width: ;
    opacity: 1;
    overflow-y: auto;
    position: sticky;
    position: -webkit-sticky; /* Safari */
    top: 0;
}

.home-header{
    background-color: white;
    padding-bottom: 30px;
    position: sticky;
    position: -webkit-sticky; /* Safari */
    scroll-margin-top: 5rem;
    box-shadow: 0 2.8px 1px rgba(0, 0, 0, 0.034);
    top: 0;
    z-index: 1;
}

.date-container{
    color: $grey;
    font-size: $is-size-4;
    margin-left: 15px;
}

.brand-motto{
    font-size: $is-size-4;
    font-weight: $bold-lato;
}


.feed-container{
    margin: 0 10px 0 15px;
}