.wall-modal {
  width: 100%;
  text-align: left;
  font-size: 1.6rem;

  strong > div {
    display: inline-block;
  }

  strong > span {
    font-weight: 300;
  }

  .link-banner {
    background: none;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.25;
    border: none;
    padding: 0 !important;
    cursor: pointer;
    color: black;
    &:hover {
      color: #3273dc;
    }
  }

  .link-wall {
    background: none;
    border: none;
    font-size: $is-size-6;
    padding: 0 !important;
    cursor: pointer;
  }

  .go-back {
    background: transparent !important;
    color: $white !important;
    font-weight: bold;
    border: none !important;
  }

  .todolegal-logo {
    width: 3.5em;
  }

}

.logo-row {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 9999;

  .todolegal-logo {
    width: 6.5em;
  }
}

.wall {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 9999;

  .logo-row {
    margin-bottom: 4%;
    margin-top: 0%
  }
}

.banner-download {
  background-color: $tl-grey;
  position: sticky;
  strong > div {
    display: inline-block;
  }

  strong > span {
    font-weight: 300;
  }
  .link-banner {
    background: none;
    color: $white;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.25;
    border: none;
    padding: 0 !important;
    cursor: pointer;

    @media (max-width: 767px) {
      font-size: 1.1rem;
    }
  }

  .subtitle{
    @media (max-width: 767px) {
      font-size: 1.1rem;
    }
  }
}

.is-content-text-modal{
  font-size: $is-size-7;
  margin-top: 1em;
  text-align: center;
}

.modal .delete-container{
  display: flex;
  justify-content: flex-end;
}

.modal .delete-btn{
  background-color: $primary;
  &:hover{
    background-color: $primary;
  }
}

.modal

.is-title-modal{
  font-size: $is-size-5;
  text-align: center;
}

.modal .is-link{
  text-decoration: underline;
  margin-bottom: 1rem;
}