
.avatar--container{
  display: none;
}

.navbar-brand{
  justify-content: space-between;
}


.main--navbar {
  background-color: $grey-darker !important ;
  color: $white;
  padding-left: 10%;
  padding-right: 10%;
  min-height: 4rem !important;

  a {
    color: $white;
    &:hover {
      background-color: $grey-darker !important;
      color: $white;
    }
  }

  @media (max-width: 767px) {
    // padding: 0.2rem 0.5rem;

    .logo-letter {
      height: 1.5rem;
    }

    .suscribe-item{
      padding-right: 0px;
    }

    .valid-logo-item{
      padding-left: 0.3rem;
    }

    .vertical-line{
      max-height: 35px !important;
    }
  }

  .logo-letter {
    max-height: 2rem;
  }

  .logo-icon {
    max-height: 3rem;
  }
}

@media (max-width: 767px) {
  .main--navbar {
    padding-left: 7%;
  }
}

.mobile--menu{
  background-color: $grey-darker !important;
}

.mobile--menu .navbar-end .navbar-item{
  color: $white;
  &:hover {
    background-color: $grey-darker !important;
  }
}

.vertical-line{
  height: 45px;
  margin-left: 11px;
  max-height: 45px !important;
  width: 1px;
}

//Logged out navbar

.navbar-burger{
  margin-top: 1% !important;
}

@media (max-width: 767px) {
  
  .navbar-burger{
    margin-top: 2.5% !important;
  }
}