// $card-shadow: none;
// $card-background-color: #FBFBFB;

.card-title{
   display: flex;
   justify-content: space-between;
}

.document-card{
   margin-bottom: 15px;
}

.document-card-icon{
   margin-right: 5px;
   height: 24px;
}

.edit-icon{
   align-items: center;
   display: inline-flex;
   justify-content: center;
   height: 1.5rem;
   width: 1.5rem;
}