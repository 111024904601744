.preference-container hr {
    background-color: #C4C4C4;
}

.preference-container .is-content-text{
    margin-top: 0px;
}

.preference-container .preferences-title{
    font-size: 3em;
    font-weight: $bold-lato;
    margin-bottom: 0px;
}

.preference-container .preferences-subtitle{
    font-size: $is-size-2;
    font-weight: $bold-lato;
    margin-top: 1em;
}

.is-preference-tag{
    background: $grey-lighter;
    border: 1px solid #9F9F9F;
    border-radius: 31px;
    gap: 10px;
    padding: 4px 18px;
    margin-right: 8px;
    margin-top: 8px;

    &:hover {
        cursor: pointer;
    }
}

.tag-selected{
    background: $blue;
    color: $white;
}

.frequency-list .frequency-selected{
    background: $blue;
    color: $white;
}

.buttons .is-info{
    border-radius: 8px;
}

.preferences-notification .go-back-button{
    text-decoration: underline;
}

.preferences-notification-danger{
    color: $warning-color;
}