//SEARCH CONTAINER POSITION

.arrow-advanced-search{
  display:none;
  }

.advance-search-mobile{
  display:none;
}
//hide calendar icon in chrome based browsers
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    // display: none;
    // -webkit-appearance: none;
    opacity: 0;
}

//ANIMATION FOR MOBILE

.modal-card{animation: SlideOut 1s, slideIn .5s linear;

}
@keyframes slideIn {
    0% {
      transform: translateY(400px);
      animation-timing-function: ease-out;
    }
  
    80% {
      transform: translateY(10px);
      animation-timing-function: ease-out;
    }
    100% {
      transform: translateY(0px);
      animation-timing-function: ease-in;
    }
  }

 
//ICONS
.advanced-search-icon{
    margin: 0 15px -5px 10px;
    max-height: 32px;
    margin-right: 5px;

}

.close-advanced-search-icon, .filter-icon{
  filter: invert(26%) sepia(87%) saturate(7457%) hue-rotate(221deg) brightness(91%) contrast(95%);
  fill: $primary;
}

//SELECTS STYLES

.select-input{
  background-color: $grey-lightest !important;
  border: none;
}

.title-advanced-search{
    font-size: $is-size-3;  
}

//DATE INPUT STYLES
.date-grouped-field{
  justify-content: space-between !important;
}

.date-input{
  background-color: $grey-lightest !important;
  border: none;
  height: 30px;
  font-size: $is-size-8;

  &::-webkit-calendar-picker-indicator {
    padding-left: 100px;
  }
}

.date-icon{
  height: 22px;
  margin-right:0px;
}

.date-wrapper{
  width:100%;
  
  display:block;
}

.date-picker-wrapper{
  display: inline-block;
  // width: 90%;
}


  .modal-card-body{
    position: fixed;
    bottom:0;
    width: -webkit-fill-available;
    box-shadow: 5px -2px 9px #00000069;
    transition:all 10s;
    transform:translateY(0);
  

}

@media only screen and (min-width:780px){
  .modal-card-body{
    position: fixed;
    left:0;
    right:0;
}
}


    ////////////////////Advanced search buttons tablet and mobile display////////////////

@media only screen and (max-width:1100px){
 
  .advanced-search{
      display: none;
  }
  .arrow-advanced-search{
  display:block;
  }

  .advance-search-mobile{
    display:flex !important;
  }

  .left-container{
    width:100% !important;


  }

  .columns{
    display:block !important;
  }
}