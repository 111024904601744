.user-avatar {
    border-radius: 50% !important;
    background-color: $primary;
    font-weight: bold;
    font-size: 16px;
    height: 55px !important;
}

.dropdown-menu{
    background-color: $grey-darker !important;

    .dropdown-content{
        background-color: $grey-darker !important;

    }

    .dropdown-item:hover{
        background-color: $white !important;
        color: #000000 !important;
    }
}

.menu-logo{
    margin-right: 3px;
    width: 13%;
}

.leyabierta-black, .leyabierta-white{
    margin-right: 5px;
}

/* Hide black icon by default */
.todolegal-black{
    display: none;
}

.leyabierta-black{
    display: none;
}

.libreria-black{
    display: none;
}

/* Show black icon when hovering over the link */
.dropdown-item:hover .todolegal-black{
    display: inline-block;
}

.dropdown-item:hover .leyabierta-black{
    display: inline-block;
}

.dropdown-item:hover .libreria-black{
    display: inline-block;
}

/* Hide original icon when hovering over the link */

.dropdown-item:hover .todolegal-white{
    display: none;
}

.dropdown-item:hover .leyabierta-white{
    display: none;
}

.dropdown-item:hover .libreria-white{
    display: none;
}

