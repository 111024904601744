.related-documents-title{
    color: $grey;
    font-size: $is-size-2;
    margin-bottom: 2em;
    padding-left: 2em;
}

.download-title{
    margin-left:5px;
    font-family: $family-primary;
    
}

.download-icon, .share-icon{
    height:24px;
    filter: invert(26%) sepia(87%) saturate(7457%) hue-rotate(221deg) brightness(91%) contrast(95%);
    fill: $primary;
}

.twitter-share-container{
    line-height: 3;
}

.buttons-container{
    padding-left: 0px !important;
}

.download-container{
    margin-right: 0px !important;
}