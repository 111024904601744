//import google fonts
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Merriweather:wght@300;400;700;900&display=swap');

//Colors
$blue: #1A7FFA;
$grey: #666A86;
$grey-darker: #171F2A;
$purple:#8527EF;
$grey-lighter:#EFEFEF;
$grey-lightest: #F5F5F5;
$grey-dark:#263238;
$tl-grey: #EFF3F7;
$white: #FFF;

//Notification colors
$warning-background: #F93D360D;
$warning-border: #FF6F00;
$warning-color: #FF3400;

$info-background: #eff5fb;
$info-border: #084e88;
$info-color: #296fa8;

//Sizes
$is-size-1: 4.25em;
$is-size-2: 2.25em;
$is-size-3: 2.00em;
$is-size-4: 1.25em;
$is-size-5: 1.12em;
$is-size-6: 1em;
$is-size-7: 0.85em;
$is-size-8: 0.75em;
$is-size-9: 0.60em;

//Font weights

$black-lato: 900;
$bold-lato: 700;

//font family & fonts
$family-primary: 'Lato', 'Merriweather', sans-serif, -apple-system, "Segoe UI", "Open Sans", "Helvetica Neue";
$family-body: 'Merriweather', serif, 'Times New Roman';

//Color classes
$primary: $blue;
$info: $blue;

// $tablet: 768px;