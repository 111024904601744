$card-radius: 14px;
.mailing-card{
    // border-radius: 14px !important;
    background-color: $tl-grey !important;
    padding-bottom: 6px;
    padding-top: 6px;
}

.mailing-card .is-title-document{
    color: $grey-darker;
    font-size: $is-size-4;
    font-weight: $black-lato;
}

.mailing-card .mailchimp-alert{
    color: $grey-darker;
}

.mailing-card .delete{
    right: 0.5rem;
    position: absolute;
    top: 0.5rem;
}

.mailing-card-text{
    color: $grey-darker;
}

.mailing-card-hidden{
    @extend .mailing-card;
    display: none;
}

.mailing-card p{
    color: $grey-darker;
}

.mailing-card input{
    box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
}
