
.search-button{
    @include from(1024px) {
        width:150px;
    }
}

.is-title-brand{
    @include mobile{
        font-size: $is-size-2;
    }
}

.is-title-document{
    @include mobile{
        font-size: $is-size-4;
    }
}

.is-title-modal{
    @include mobile{
        font-size: $is-size-7;
    }
  }

.institution-tags{
    @include mobile{
        font-size: $is-size-7;
    }
}

.is-content-text{
    @include mobile{
        font-size: $is-size-8;
    }
}

.link-wall{
    @include mobile{
        font-size: $is-size-8;
    }
}

.is-content-text-modal{
    @include mobile{
        font-size: $is-size-8;
    }
    
  }

.related-documents-title{
    @include mobile{
        font-size: $is-size-4;
    }
}

.brand-motto{
    @include mobile{
        font-size: $is-size-6;
        margin-left: 30px;
        margin-right: 30px;
    }
}

.date-input{
    @include desktop {
        width:100%;
        max-width:270px;
        margin-left:10px;
        margin-bottom:5px;
  }
}

.date-picker-wrapper{
    @include desktop{
        width: 90%;
    }
}

.preference-container .preferences-title{
    @include mobile{
        font-size: $is-size-3;
    }
}

.preference-container .preferences-subtitle{
    @include mobile{
        font-size: $is-size-4;
    }
}

.preference-container .is-large{
    @include mobile{
        font-size: 1rem;
    }
}

.logo-row .todolegal-logo{
    @include mobile{
        width: 4.5em;
    }
}