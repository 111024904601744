
//is there a way to not use 'important'?
.search-bar{
    margin-right: 0px !important;
}

.search-bar-container{
    margin-top: 5px;
}
// .search-button{
//     // @include from(1024px) {
//     //     width:150px;
//     // }
// }
