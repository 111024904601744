* {
  font-family: $family-primary;
}

//FOOTER GENERAL STYLES

.footer-p {
  color: #505864;
  margin-right: 1rem;
}

.footer-p a {
  color: #505864;
}

.footer-p a:hover {
  color: #3273dc;
}

//LINKS GENERAL STYLES
.is-blue-link{
  color: #1A7FFA !important;
  text-decoration: underline;
}

.is-link {
  color: black;

  &:hover {
    color: none !important;
  }

  &:active {
    color: none !important;
  }
}

//GENERAL STYLES FOR DOWNLOAD BUTTON

.button-download{
  background-color: $primary;
}

//BUTTONS GENERAL STYLE


.is-normal-button{
  width:150px;
    
  &:hover{
      // background-color: #983DFF !important;
      transform: translateY(-1px);
  }

}


//TITLES GENERAL STYLES

.is-title-document{
  color:$grey-darker;
  font-size: $is-size-3;
  line-height:1.25em ;
  font-weight: $bold-lato;
}

.is-title-brand{
  font-size: $is-size-1;
  color: $grey-darker;
  font-weight: $black-lato;
}


.is-filter-title{
  font-size: $is-size-4;
}


//TAGS GENERAL STYLES
.is-tag{
  background-color: $grey-lighter;
  border: 1px solid $grey-dark;
  border-radius: 290486px;
  display: inline-block;
  font-size: $is-size-8;
  margin-right: 5px;
  padding: 1px 10px;
  max-width:100%;
}


.institution-tags{
  font-size: $is-size-4;
}

//CONTENT GENERAL STYLES
.is-content-text{
  font-family: $family-body;
  font-size: $is-size-6;
  margin-top: 1em;
}

.footer-container{
  background-color: $white;
  bottom: 0;
  left: 0;
  position: sticky;
}

// NOTIFICATIONS GENERAL STYLES

.is-info-notification{
  background-color: $info-background !important;
  border: 1px solid $info-border;
  color: _$info-color;
  margin-bottom: 0px !important;
  text-align: center;
}

.is-warning-notification{
  background-color: $warning-background !important;
  border: 1px solid $warning-border;
  color: $warning-color;
  margin-bottom: 0px !important;
  text-align: center;
}

// DISABLED ELEMENTS

.disabled {
  opacity: 0.7;
}

.is-success-notification{
  
}

@media only screen and (max-width:768px){

  .date-container{
    font-size:1em !important;
  }

  .home-header{
    padding-bottom: 16px !important;
  }

  .level-left + .level-right{
    margin-top: 2px !important;
    font-size: .80em !important;
  }

  .level-left{
    font-size: .90em !important;
  }
}